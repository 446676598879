.h2-inthe-charts{
    background-color: #00afe2;
    color: white;
    padding: 10px 20px;
    margin-bottom: 50px;
}
.pie-dash{
    width: 500px;
    /* padding: 10px; */
}
.dash{
    /* background-color:#00afe2; */
}
.do-it-flex{
    display: flex;
    justify-content: space-between;
  /* height: 800px; */
  margin-top: 100px;
  box-sizing: border-box!important;
}
.s-dash-board{
    /* box-shadow: 6px 11px 45px -5px rgba(0,0,0,0.64) inset ; */
    /* box-shadow: 6px 11px 45px -5px #00afe2 inset; */
    padding-bottom: 100px;
    border-radius: 30px;
}

.bar-graph-dash{
    padding: 0px 100px;
}
.s-dash-board h2{

}
.bar-inside-flex{
    width: 50%;
}