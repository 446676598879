@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');


*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
:root {
  --color-primary: #00afe2;
  --color-primary--light: #c1f1ff;
  --color-table-head: #d3f5ff;
  --color-dark: #0588b0;
  --light: #ffffff;
  --light-two: #f5f5f5;
  --dark: #000;
  --dark-web-color: #01294d;
}

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
}

.models{
  position: absolute;
  display: flex;
  background-color: black;
  width: 100%;
  height: 100vh;
  top: 0px;
}

.selected-table-true{
  background-color: var(--color-primary--light);
}