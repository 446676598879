.model-con {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0,0.6);
    /* opacity: 90%; */
    z-index: 3;
}

.model-box {
    /* background-color: white; */

    padding: 20px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

.model-box2 {
    background-color: white;
    height: 90vh;
    overflow-y: scroll;
    /* overflow-x: hidden; */
}

/* Rectangle 81 */



/* Style the scrollbar */
.model-box2::-webkit-scrollbar {
    width: 2px;
    /* Width of the scrollbar */
}

/* Track */
.model-box2::-webkit-scrollbar-track {
    background: #eeeeee;
    /* Color of the scrollbar track */
}

/* Handle */
.model-box2::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar handle */
}

/* Handle on hover */
.model-box2::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color of the scrollbar handle on hover */
}

.test {
    /* background-color: red; */
    padding: 30px;
    z-index: 10;

}

.dummy {
    position: absolute;
    top: 0px;
    height: 350px;
    width: 100%;
    z-index: -1;
}

.inner_Page {
    min-height: 60vh;

}