.inventory-table {
    margin: 2rem 1rem;
    font-size: 12px;

    /* width: 600px; */
    /* background-color: red; */
  }
  .inventory-tables{
    margin: 2rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    /* display: flex;
    flex-wrap: row-wrap; */
  }
  .material-div{
    margin: 5rem 7rem;
  }


/* tab */
@media screen and (max-width:1400px){
  tr,th,td{
    font-size: 12px!important;
    padding: 6px!important;
  }
  .material-div{
    margin: 20px;
  }

    .inventory-table {
        /* margin: auto; */
        /* margin-left: 20px; */
        /* margin-right: 20px; */
        transform: scale(1);
        /* transform: translateX(-50%) translateY(-50%); */
    
      }
      .login-btn a{
        font-size: 1.2rem!important;
      }
      
 .logo h2{
  font-size: 1.2rem;
 }
      .inventory-tables{
     
        /* grid-template-columns: 1fr ; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
      }
} 
/* phone  */
@media screen and (max-width:500px){
    .inventory-table {
        margin: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        transform: scale(1);
        /* transform: translateX(-50%) translateY(-50%); */
    
      }
      .inventory-tables{
     
        /* grid-template-columns: 1fr ; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
} 


@media screen and (max-width:1800px){
  .inventory-tables{
    grid-template-columns: 1fr ;
  }
}

.inven-cap{
display: flex;
justify-content: space-between;
}
.cap-item-in{
  width: 40%;
}